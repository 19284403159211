<template>
  <div>
    <a-modal
      width="880px"
      title="Excluded Intervals"
      :visible="visible"
      :confirm-loading="confirmLoading"
      :closable="false"
    >
      <!-- {{ tableList }} -->
      <a-table :columns="columns" :data-source="data" size="small">
        <template slot="index" slot-scope="text, record, index">
          {{ index + 1 }}
        </template>
        <template slot="action" slot-scope="text, record">
          <a-popconfirm
            title="Are you sure that you want to remove this item?"
            @confirm="onDelete(record.index)"
            okText="enter"
            cancelText="cancel"
          >
            <a href="javascript:;" style="color: red">Delete<a-icon type="delete" /></a>
          </a-popconfirm>
        </template>
      </a-table>
      <template slot="footer">
        <a-button key="back" @click="handleCancel" class="btn"> Cancel </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
const columns = [
  {
    title: '#',
    dataIndex: 'index',
    key: 'index',
    scopedSlots: { customRender: 'index' },
  },
  {
    title: 'excludedStartRecordedAt',
    dataIndex: 'excludedStartRecordedAt',
  },
  {
    title: 'excludedEndRecordedAt',
    dataIndex: 'excludedEndRecordedAt',
  },
  {
    title: 'systemDate',
    dataIndex: 'systemDate',
  },
  {
    title: 'action',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' },
  },
]

export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      ModalText: 'Content of the modal',
      visible: false,
      confirmLoading: false,
      tableList: [],
      columns,
    }
  },
  watch: {
    data: {
      handler: function (newValue, oldValue) {
        // console.log('数据变化了：', newValue, oldValue)
        this.tableList = newValue
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    showModal() {
      this.visible = true
    },
    handleOk(e) {
      this.ModalText = 'The modal will be closed after two seconds'
      this.confirmLoading = true
      setTimeout(() => {
        this.visible = false
        this.confirmLoading = false
      }, 2000)
    },
    handleCancel(e) {
      this.visible = false
    },
    onDelete(index) {
      this.tableList.splice(index, 1)
      this.$emit('del')
    },
  },
}
</script>

<style lang="less" scoped>
/deep/.btn {
  border-radius: 0.25rem;
  background-color: rgb(127, 98, 173);
  border-color: rgb(127, 98, 173);
  color: #fff;
}
</style>